import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import CardActivatePage from './pages/CardActivatePage';
import AuthPage from './pages/AuthPage';
import { SuccessPage } from './pages/SuccessPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { OnlyHome } from './hoc/OnlyHome';
import MaintenancePage from './pages/MaintenancePage';

function App() {
  // Read maintenance mode from environment variable
  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE === 'TRUE';

  // If in maintenance mode, show maintenance page
  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route path="/" element={<CardActivatePage />} />
          <Route
            path="add"
            element={
              <OnlyHome>
                <AuthPage />
              </OnlyHome>
            }
          />
          <Route
            path="success"
            element={
              <OnlyHome>
                <SuccessPage />
              </OnlyHome>
            }
          />
          <Route
            path="*"
            element={
              <OnlyHome>
                <NotFoundPage />
              </OnlyHome>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;