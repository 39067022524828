import React from 'react';
import { WrenchIcon, ClockIcon, MailIcon } from 'lucide-react';
import { maintenanceConfig } from '../config/maintenance';
import desktopImg from '../assets/img/desktop_img.png';
import desktopImgMin from '../assets/img/desktop_img_min.png';

const MaintenancePage = () => {
  return (
    <main className="relative">
      {/* Background gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50/80 via-transparent to-purple-50/80 pointer-events-none" />

      <div className="container relative z-10">
        <div className="mainpage">
          <div className="left">
            {maintenanceConfig.title && (
              <h1 className="text-[44px] font-bold leading-tight mb-9 tracking-tight bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-clip-text text-transparent">
                {maintenanceConfig.title}
              </h1>
            )}
            <form className="form shadow-xl backdrop-blur-sm bg-white/95">
              {maintenanceConfig.message && (
                <div className="form-title text-2xl font-bold mb-8 tracking-tight bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                  {maintenanceConfig.message}
                </div>
              )}
              {maintenanceConfig.estimatedTime && (
                <div className="helper-box bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl p-6 mb-8 transition-all hover:shadow-md">
                  <div className="helper-wrapper">
                    <div className="help-left bg-white rounded-full p-3 shadow-md border border-blue-100">
                      <WrenchIcon size={32} className="text-blue-600" />
                    </div>
                    <div className="help-right ml-4">
                      <h1 className="text-lg font-semibold text-blue-900 mb-2 tracking-tight">
                        Плановые технические работы
                      </h1>
                      <p className="text-blue-700 leading-relaxed">
                          <b>{maintenanceConfig.estimatedTime}</b>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {maintenanceConfig.contactInfo && (
                <div className="form-data bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl p-6 transition-all hover:shadow-md">
                  <div className="flex items-center justify-center gap-4">
                    <div className="bg-white rounded-full p-3 shadow-md border border-purple-100">
                      <MailIcon size={24} className="text-purple-600" />
                    </div>
                    <p className="form-text text-purple-700 leading-relaxed font-medium">
                      {maintenanceConfig.contactInfo}
                    </p>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="right">
            <img
              src={desktopImg}
              alt="Maintenance"
              className="w-full h-auto rounded-2xl shadow-xl transform hover:scale-[1.02] transition-transform duration-300"
            />
          </div>
        </div>
      </div>
      <div className="mobile-img">
        <img
          src={desktopImgMin}
          alt="Maintenance Mobile"
          className="w-full h-auto"
        />
      </div>
    </main>
  );
};

export default MaintenancePage;