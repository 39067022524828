

function ErrTemplates(err_code){
    const errors = {
        'E001':'Ошибка! Вы ввели неверные данные, попробуйте снова',
        'E002':'Ошибка! Вы ввели неверные данные, попробуйте снова',
        'E003':'Ошибка! Вы ввели неверные данные, попробуйте снова',
        'E004':'Ошибка сервера',
        'E005':'Ошибка сервера',
        'E007':'Карта уже успешно привязана к вашему табельному номеру, перейдите по адресу https://meal.gift-cards.ru/activation и активируйте карту.',
        'E006':'Введенные данные уже используются',
        'E111':'Проверьте правильность заполнения поля!',
        "E013": "Карта уже была привязана к пользователю в этом квартале",
    }
    return errors[err_code]
}
function ErrTemplateLink(){
    return {
        'start': 'Карта уже успешно привязана к вашему табельному номеру, перейдите по адресу ',
        'end': ' и активируйте карту.',
        'link': 'https://meal.gift-cards.ru/activation'
    }
}

export {ErrTemplates, ErrTemplateLink}